<template>
    <div id="wrap">
        <top page="works" @search="handleSearch" :showSearchButton="true" />
        <div class="mobile-wrap">
			<div class="tab">
				<div class="mainBox">
					<a 
						v-for="category in categoryList" 
						:key="category.id"
						@click="changeCategory(category)"
						:class="category.id === selectedCategory.id ? 'on' : ''" 
					>
						{{category.value}}
					</a>
				</div>
			</div>
            <div class="module-1">
                <div class="mainBox">
                    <div class="list-box">
                        <div
                            class="list wow fadeInUp"
                            data-wow-offset="0"
                            data-wow-delay="0.1s"
                            v-for="item in photoList"
                            :key="item.id"
							@click="handleShowWindow(item)"
                        >
                            <div class="pic">
                                <img :src="item.thumb.absolutePath" />
                            </div>
                        </div>
                    </div>
                </div>
				<div class="loadMore" v-if="paging.pageIndex < paging.pageCount">
					<a @click="handleLoadMore">+加载更多+</a>
				</div>
            </div>
			<!-- 详情 -->
			<div class="works-window" style="display: none;">
				<div class="window-con">
					<div class="pic">
						<div class="windos-close" @click="handleCloseWindow"></div>
						<img :src="selectedPhoto.photo.absolutePath" />
					</div>
					<div class="btn-box">
						<a class="prev" @click="handlePrev" v-if="currentIndex !== 0"><span class="iconfont iconarrow-lift"></span></a>
						<a class="next" @click="handleNext" v-if="currentIndex !== photoList.length - 1"><span class="iconfont iconarrow-right"></span></a>
					</div>
					<div class="con windos-close">
						<span>{{selectedPhoto.name}}</span>
						<span class="download">下载: {{selectedPhoto.download}}</span>
						<div 
							class="sucai sucai-btn" 
							@click="handleShowDesc"
							v-if="selectedPhoto.materialList.length > 0"
						>
							材料单
						</div>
						<a class="pc"
							@click="downloadImg(selectedPhoto)"
							target="_blank"
						>下载</a>
						<a class="mobile download-info" @click="handleShowInfoWindow">下载</a>
					</div>
				</div>
			</div>
			<!-- 素材 -->
			<div class="sucai-window" style="display: none;">
				<div class="window-con">
					<div class="windos-close" @click="handleCloseDesc"><span class="iconfont iconclose"></span></div>
					<div class="con">
						<div 
							v-for="material in selectedPhoto.materialList" 
							:key="material.id"
						>
							<span>{{material.name}}</span>
							<a :href="material.href">购买</a>
						</div>
					</div>
				</div>
			</div>
			
			<!-- 下载提示 -->
			<div class="info-window" :style="`display: ${infoWindowDisplay};`">
				<div class="window-con">
					<div class="windos-close" @click="handleHideInfoWindow"></div>
					<div class="con">
						长按图片保存
					</div>
				</div>
			</div>


        </div>
        <bottom />
        <side />
    </div>
</template>


<script>
import top from "./components/top.vue";
import bottom from "./components/bottom.vue";
import side from "./components/side.vue";
import { wxShare } from "@/utils/share.js"
export default {
    page: {
        title: "图库",
    },
    data() {
        return {
            photoList: [],
			categoryList: [
				{
					id: 'all',
					value: '全部'
				}
			],
			selectedCategory: {
				id: 'all',
				value: '全部'
			},
            paging: {
                pageIndex: 1,
                pageSize: 50,
                recordCount: 0,
                pageCount: 0,
            },
			selectedPhoto: {
				name: '',
				photo: {
					absolutePath: ''
				},
				desc: '',
				materialList: []
			},
			search: {
				name: ''
			},
			currentIndex: 0,
			infoWindowDisplay: 'none'
        };
    },
    components: {
        top,
        bottom,
        side,
    },
    methods: {
		handleWxShare(photoList) {
			let url = window.location.href

			this.$api.wechat.mpwechat.getJsapiSignature(url)
			.then(response => {
				if(response.code === '0') {

					let browser = window.navigator.userAgent.toLowerCase();	

					// 非微信浏览器不执行分享代码
					if (browser.match(/MicroMessenger/i) != 'micromessenger') {
						return;
					} 	

					//微信加签
                    let obj = {
                        appId: response.data.appId,
                        nonceStr: response.data.nonceStr,
                        signature: response.data.signature,
                        timestamp: response.data.timestamp,
                        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
                    };


                    // var shareWxLink = encodeURIComponent(url);加密
                    let shareData = {
                        title: '流行花艺图库【实时更新中】', // 分享标题
                        desc: '无需注册、无付费，免费下载使用（禁出版）',
                        link: response.data.url,
                        imgUrl: photoList[0].photo.absolutePath // 分享图标
                    };

					//引用
                    wxShare(obj, shareData);
				}
				else {
					this.$message.info(response.message)
				}
			})
		},
		handleShowInfoWindow() {
			this.infoWindowDisplay = 'block'
		},
		handleHideInfoWindow() {
			this.infoWindowDisplay = 'none'
		},
        handleLoadMore() {
            this.$api.focus.photoWall.findAll
			(	
				this.search.name,
				this.selectedCategory.id === 'all' ? '' : this.selectedCategory.id,
				this.paging.pageIndex + 1, 
				this.paging.pageSize
			)
			.then((response) => {
				if (response.code === "0") {
					this.photoList = this.photoList.concat(
						response.data.photoList
					);
					this.paging = response.data.paging;
					this.$nextTick(() => {
						new this.$wow.WOW().init();
					});
				} else {
					this.$message.error(response.message);
				}
			});
        },
        handleFindAllPhoto() {
            this.$api.focus.photoWall.findAll
			(
				this.search.name,
				this.selectedCategory.id === 'all' ? '' : this.selectedCategory.id,
				this.paging.pageIndex, 
				this.paging.pageSize
			)
			.then((response) => {
				if (response.code === "0") {
					this.photoList = response.data.photoList;
					this.paging = response.data.paging;
					this.$nextTick(() => {
						new this.$wow.WOW().init();
					});

					if(this.photoList.length > 0) {
						this.handleWxShare(this.photoList)
					}
				} else {
					this.$message.error(response.message);
				}
			});
        },
		// 图片下载
		downloadImg(img){
			var image = new Image()
			// 解决跨域 Canvas 污染问题
			image.setAttribute("crossOrigin", "anonymous")
			image.onload = function() {
				let canvas = document.createElement("canvas")
				canvas.width = image.width
				canvas.height = image.height
				let context = canvas.getContext("2d")
				context.drawImage(image, 0, 0, image.width, image.height)
				let url = canvas.toDataURL("image/png") //得到图片的base64编码数据
			
				let a = document.createElement("a") // 生成一个a元素
				let event = new MouseEvent("click") // 创建一个单击事件

				a.download = img.name  // 设置图片名称
				a.href = url // 将生成的URL设置为a.href属性
				a.dispatchEvent(event) // 触发a的单击事件
			}
 
			image.src = img.photo.absolutePath
		},
		handleShowWindow(photo) {
			this.selectedPhoto = photo;
			$('.works-window').fadeToggle('');

			this.currentIndex = this.photoList.findIndex(item => {
				if(item.id == this.selectedPhoto.id) {
					return true;
				}
			})

			// 增加下载量
			this.$api.focus.photoWall.addDownload(photo)
			.then(response => {
			
			})
		},
		handleCloseWindow() {
			$('.works-window').fadeOut('');
			$('.sucai-window').fadeOut('');
			this.selectedPhoto = {
				name: '',
				photo: {
					absolutePath: ''
				},
				desc: '',
				materialList: []
			} 
		},
		handleFindAllCategory() {
			this.$api.settings.dictionary.findAll('照片墙')
			.then(response => {
				if(response.code === '0') {
					this.categoryList = this.categoryList.concat(response.data)
				}
				else {
					this.$message.error(response.error)
				}
			})
		},
		changeCategory(category) {
			this.selectedCategory = category;
			this.handleFindAllPhoto()
		},
		handleSearch(keywords) {
			this.search.name = keywords;
			this.handleFindAllPhoto()
		},
		handleShowDesc() {
			$('.sucai-window').fadeToggle('');
		},
		handleCloseDesc() {
			$('.sucai-window').fadeOut('');
		},
		handleNext() {
			this.currentIndex = this.photoList.findIndex(item => {
				if(item.id == this.selectedPhoto.id) {
					return true;
				}
			})

			this.currentIndex++

			if(this.photoList.length === this.currentIndex) {
				return
			}

			this.selectedPhoto = this.photoList[this.currentIndex]

			// 增加下载量
			this.$api.focus.photoWall.addDownload(this.selectedPhoto)
			.then(response => {

			})
		},
		handlePrev() {
			this.currentIndex = this.photoList.findIndex(item => {
				if(item.id == this.selectedPhoto.id) {
					return true;
				}
			})

			if(this.currentIndex === 0) {
				return
			}

			this.currentIndex--

			this.selectedPhoto = this.photoList[this.currentIndex]

			// 增加下载量
			this.$api.focus.photoWall.addDownload(this.selectedPhoto)
			.then(response => {

			})
		}
    },
    mounted() {
		this.handleFindAllCategory();
        this.handleFindAllPhoto();
    },
};
</script>

<style src="../style/css/works.css" scoped></style>
<style scoped>
</style>